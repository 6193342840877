import { Component, OnInit, Input , HostListener, ViewChild, ElementRef } from '@angular/core';
import { SharedService } from '@app/core/services/shared.service';
import { trigger, transition, animate, style } from '@angular/animations'
import { Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { GlobalSearchService } from '@app/core/services/global-search.service';
import { LayoutService } from '../layout.service';
import { StorageService } from '@app/core/services/storage.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('500ms ease-in', style({ opacity: '1' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ opacity: '0' }))
      ])
    ])
  ]
})
export class LayoutComponent implements OnInit {
  showIcon = true;
  value = "";
  public showSearch = false;
  data = 6;
  public globalSearchListner: any;
  public dismissNotify: any;
  public searchText: any;
  public timeout = null;
  public showRedirectPanels = false;

  @ViewChild('keywordsInput') private nameField: ElementRef;


  constructor(public sharedService: SharedService, public router: Router, location2: LocationStrategy, private globalSearchService: GlobalSearchService, public layoutService: LayoutService, public storage : StorageService) {
    location2.onPopState(() => {
      this.closeSearch();
      // alert(window.location);
    });
  }

  ngOnInit() {
    this.checkUser();
    this.value = "";
    this.globalSearchListner = this.globalSearchService.globalShowSearchEventListner().subscribe(
      event => {
        this.showSearch = event;
      }
    )
  }

  checkUser() {
    let orgType = localStorage.getItem('org-type');
    if (orgType === 'SuperAdmin' || orgType === 'ClientAdmin') {
      this.showRedirectPanels = true;
    }
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  closeSearch() {
    this.value = '';
    this.searchText = "";
  }

  clickedInside($event: Event) {
    this.focusSearch();
    $event.preventDefault();
    $event.stopPropagation();  // <- that will stop propagation on lower layers

  }
  @HostListener('document:click', ['$event']) clickedOutside($event) {
    // here you can hide your menu
    this.value = '';
  }

  ngOnDestroy() {
    this.globalSearchListner.unsubscribe();
  }

  focusSearch() {
    this.showSearch = !this.showSearch;
    setTimeout(() => {
      this.nameField.nativeElement.focus();
    }, 0);

  }


  // inputChangeEvent(event) {
  //   this.globalSearchService.globalSearchEventEmitter.emit({ 'value': event.target.value });
  // }


  inputChangeEvent(event) {
    //reduced api calls for search properties by time out method
    clearTimeout(this.timeout);
    this.timeout = setTimeout(t => {
      this.globalSearchService.globalSearchEventEmitter.emit({ 'value': event.target.value });
    }, 500);

  }


  resetSearch() {
    this.searchText = "";
    this.globalSearchService.globalSearchEventEmitter.emit({ 'value': "" });
  }

  redirectToDashboardPanel(){
    let authToken = this.storage.getValue('access_token') || '';
    if (authToken !== '') {
      let url = this.sharedService.getDashboardUrl();
      let adminPortalUrl = url + '/redirect?authToken=' + authToken + '&redirectURl=' + encodeURI(url + '/property/list-all');
        window.open(adminPortalUrl);
    }
  }

}
