import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedService } from 'src/app/core/services/shared.service';

@Injectable()
export class AccountAPI {

    private apiUrl = '';

    constructor(private sharedService: SharedService, private http: HttpClient) {
        this.apiUrl = this.sharedService.getAPIUrl();
    }

    userLogin(object) {
        const options = {
            headers: new HttpHeaders({
                'content-type': 'application/json'
            })
        }
        let body = JSON.stringify(object);
        return this.http.post(this.apiUrl + '/api/Users/Login', body, options);
    }
    
    /*************************************************************
   Parametre: forgetPasswordRequest
   Description: This method will reuest for reset the password
  **************************************************************/

    forgetPasswordRequest(request) {
        const options = {
            headers: new HttpHeaders({
                'content-type': 'application/json'
            })
        }
        let body = JSON.stringify(request);
        return this.http.post(this.apiUrl + '/api/Users/ForgotPasswordRequest', body, options);
    }
    changePassPassword(request) {
        const options = {
            headers: new HttpHeaders({
                'content-type': 'application/json'
            })
        }
        let body = JSON.stringify(request);
        return this.http.post(this.apiUrl + '/api/Users/ChangePassword', body, options);
    }
}