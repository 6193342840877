import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  public currentUser = {
    name: '',
    uId: '',
    orgType: ''
  };

  public userData: any;
  constructor() {
   }
}
