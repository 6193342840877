import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import swal from 'sweetalert2';

export class HttpErrorInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    let errorMessage = '';
                    if (error.status === 401) {
                        localStorage.clear();
                        window.location.reload();
                    }
                    else if (error.status === 403) {
                        swal({
                            type: 'warning',
                            title: 'Not Permitted',
                            text: 'You are not authorised to do this action, contact your adminstrator for access claims. Please re-login to the system after getting access claims.',
                            showConfirmButton: true,
                        })
                        return throwError(error);
                    }
                    else if (error.status === 404) {
                        swal({
                            type: 'warning',
                            title: 'Not Found',
                            text: 'Resource not found.',
                            showConfirmButton: true,
                        })
                        return throwError(error);
                    }
                    else if (error.status === 409) {
                        swal({
                            type: 'warning',
                            title: 'Conflict',
                            text: 'Another request has already updated this resource. Please refresh your screen or go back to the list.',
                            showConfirmButton: true,
                        })
                        return throwError(error);
                    }
                    else if (error.status === 500) {
                        swal({
                            type: 'warning',
                            title: 'Error',
                            text: "Something went wrong. Try re-login to TAP system. Please contact support@tap-in.co.uk, if issue still persists.",
                            showConfirmButton: true,
                        })
                        return throwError(error);
                    }
                    else if (error.status === 503) {
                        swal({
                            type: 'warning',
                            title: 'Service Unavailable',
                            text: "We're currently updating the system.Please try again later.",
                            showConfirmButton: true,
                        })
                        return throwError(error);
                    }
                    else if (error.status === 504) {
                        swal({
                            type: 'warning',
                            title: 'Not Permitted',
                            text: "Not able to connect to the TAP system. Please check your network or firewall settings.",
                            showConfirmButton: true,
                        })
                        return throwError(error);
                    }
                })
            )
    }
}