import { Component, OnInit } from '@angular/core';
import { UsersAPI } from '@app/shared/apis/users.api';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  public dashboardData : any ;

  constructor(private usersApi : UsersAPI) { }

  ngOnInit() {
    this.getDashboardData();
  }

  getDashboardData(){
        this.usersApi.getAdminDashboardData().subscribe(
          response => {
            this.dashboardData = response; 
          })
     }
}
