import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MainComponent } from './main/main.component';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { LayoutComponent } from './layout/layout.component';
import { CustomMaterialModule } from '@app/shared/modules/angular-material/material.module';
import { FlexLayoutModule } from "@angular/flex-layout";
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule, MatMenuModule } from '@angular/material';
import { CustomNgxChartsModule } from '@app/shared/modules/ngx-charts/ngx-charts.module';
import { LayoutService } from './layout.service';
import { UsersAPI } from '@app/shared/apis/users.api';
import { RedirectComponent } from './redirect/redirect.component';
import { SharedService } from '@app/core/services/shared.service';


@NgModule({
  declarations: [SidebarComponent, MainComponent, LayoutComponent, LoginComponent, DashboardComponent,  RedirectComponent],
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule,
    FormsModule,
    CustomMaterialModule,
    FlexLayoutModule,
    MatBadgeModule,
    MatMenuModule,
    CustomNgxChartsModule,
    ReactiveFormsModule
  ],
  exports: [
    LayoutComponent
  ],
  providers: [
    LayoutService,
    UsersAPI,
    SharedService
  ]
})
export class LayoutModule { }
