import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '@app/core/services/storage.service';
import { AccountAPI } from '@app/shared/apis/account.api';
import { UsersAPI } from '@app/shared/apis/users.api';
import { LayoutService } from '../layout.service';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.css']
})
export class RedirectComponent implements OnInit {

  public redirectURI = '';
  public authToken = '';
  public userData: any;
  public userInfo: any;

  constructor(private route: ActivatedRoute, public layoutService: LayoutService, private usersAPI: UsersAPI, private router : Router) {
    this.route.queryParams.subscribe(params => {
      this.redirectURI = params["redirectURl"] || "";
      this.authToken = params["authToken"] || "";
    });
  }

  ngOnInit() {
    if (this.authToken !== '') {
      localStorage.setItem('access_token', this.authToken);
      if (this.redirectURI !== "") {
        this.getNewUserInfo();
      }
    }
  }

  getNewUserInfo() {
    let loggedInUserInfo = JSON.parse(localStorage.getItem("user_info"));
    if (loggedInUserInfo !== undefined && loggedInUserInfo !== null && loggedInUserInfo !== "") {
      this.getNewUserInformation();
    } else {
      this.usersAPI.getUserInfo().subscribe(
        response => {
          this.userData = response;
          localStorage.setItem('user_info', JSON.stringify(this.userData));
          localStorage.setItem('org-type', this.userData.orgType);
          this.getNewUserInformation();
        }, error => {
          console.log(error);
        });
    }
  }

  getNewUserInformation() {
    let loggedInUser = JSON.parse(localStorage.getItem("user_data"));
    if (loggedInUser !== undefined && loggedInUser !== null && loggedInUser !== "") {
      this.userInfo = loggedInUser;
      window.location.href = this.redirectURI;
    } else {
      this.usersAPI.getUserInformation().subscribe(
        response => {
          this.userInfo = response;
          localStorage.setItem('info_new', JSON.stringify(this.userInfo));
          window.location.href = this.redirectURI;
        }, error => {
          console.log(error);
        });
    }
  }




}



