import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxChartComponent } from './ngx-chart/ngx-chart.component';

import { NgxChartsModule } from '@swimlane/ngx-charts';


@NgModule({
  declarations: [NgxChartComponent],
  imports: [
    CommonModule,
    NgxChartsModule
  ],
  exports:[NgxChartComponent]
})
export class CustomNgxChartsModule { }
