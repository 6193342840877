import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/router-guards/auth-guard';
import { LoginComponent } from './layout/login/login.component';
import { DashboardComponent } from './layout/dashboard/dashboard.component';
import { from } from 'rxjs';
import { RedirectComponent } from './layout/redirect/redirect.component';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'redirect', component: RedirectComponent },
  
  { path: 'account', loadChildren: '../app/modules/account/account.module#AccountModule' },
  { path: 'properties', loadChildren: './modules/properties/property.module#PropertyModule', canActivate: [AuthGuard] },
  { path: 'property-unit', loadChildren: './modules/property-unit/property-unit.module#PropertyUnitModule', canActivate: [AuthGuard] },
  { path: 'property-manager', loadChildren: './modules/property-manager/property-manager.module#PropertyManagerModule', canActivate: [AuthGuard] },
  { path: 'vendor', loadChildren: './modules/vendor/vendor.module#VendorModule', canActivate: [AuthGuard] },
  { path: 'tenant', loadChildren: './modules/tenant/tenant.module#TenantModule', canActivate: [AuthGuard] },
  { path: 'landlord', loadChildren: './modules/landlord/landlord.module#LandlordModule', canActivate: [AuthGuard] },
  { path: 'offers', loadChildren: './modules/property-offers/offers.module#OffersModule', canActivate: [AuthGuard] },
  
  { path: 'permit-data', loadChildren: './modules/permit-to-work/permit-to-work.module#PermitToWorkModule', canActivate: [AuthGuard] },
  { path: 'permit-templates', loadChildren: './modules/ptw-templates/ptw-templates.module#PtwTemplatesModule', canActivate: [AuthGuard] },

  { path: 'sms-templates', loadChildren: './modules/sms-templates/sms-templates.module#SmsTemplatesModule', canActivate: [AuthGuard] },

  { path: 'supplier-management', loadChildren: './modules/supplier-management/supplier-management.module#SupplierManagementModule', canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
