import { Component, OnInit } from '@angular/core';
import { SharedService } from '@app/core/services/shared.service';
import { StorageService } from '@app/core/services/storage.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  constructor(private sharedService: SharedService, private storageService: StorageService) { }

  ngOnInit() {
  }

  onActivate(event) {
    let loginStatus = this.sharedService.isUserLoggedIn;
    let token = this.storageService.getValue('access_token');

    if (token === null || loginStatus === false) {
      document.getElementById('main-app').classList.remove('main-margin');
    } else {
      document.getElementById('main-app').classList.add('main-margin');
    }
  }

}
