import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountAPI } from '@app/shared/apis/account.api';
import { SharedService } from '@app/core/services/shared.service';
import { UsersAPI } from '@app/shared/apis/users.api';
import { LayoutService } from '../layout.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public loading = false;
  public hide = true;
  public showSpinner = false;
  userData: any;
  userInfo: any;
  public organizationType: any;
  public redirectURl = '';
  platformId: string;
  constructor(private usersAPI: UsersAPI, public layoutService: LayoutService, private sharedService: SharedService, private accountAPI: AccountAPI, private router: Router, @Inject(PLATFORM_ID) platformId: string,
    private routerParams: ActivatedRoute) {
    
    this.platformId = platformId;
    this.routerParams.queryParams.subscribe(params => {
      this.redirectURl = params["redirectURl"] || "";
    });

  }

  ngOnInit() {
    this.sharedService.isUserLoggedIn = false;
  }

  login(mail, pwd) {
    this.loading = true;
    this.showSpinner = true;
    let loginObject = {
      userName: mail,
      password: pwd,
    }
    this.accountAPI.userLogin(loginObject).subscribe(
      response => {
        localStorage.setItem('access_token', response['access_token']);
        this.sharedService.isUserLoggedIn = true;
        this.getUserInfo();
      },
      error => {
        swal({
          position: 'center',
          title: 'Oops...',
          text: 'Username or Password incorrect',
          type: 'error'
        });
        this.loading = false;
        this.showSpinner = false
      }
    );
  }


  getUserInfo() {
    this.usersAPI.getUserInfo().subscribe(
      response => {
        this.userData = response
        localStorage.setItem('user_info', JSON.stringify(this.userData));
        localStorage.setItem('org-type', this.userData.orgType);
        this.organizationType = this.userData.orgType;
        
        if (this.redirectURl !== "") {
          window.location.href = this.redirectURl;
        } else {
          if (this.organizationType === "SuperAdmin") {
            this.router.navigate(['dashboard']);
          } else {
            this.router.navigate(['/properties/dashboard']);
          }
        }
        this.layoutService.currentUser = this.userData;
        this.getUserInformation();
      }
    )
  }

  getUserInformation() {
    debugger;
    this.usersAPI.getUserInformation().subscribe(
      response => {
        this.userInfo = response;
        localStorage.setItem('info_new', JSON.stringify(this.userInfo));
        this.layoutService.userData = response
      }
    )
  }

  route() {
    this.router.navigate(['/account/forgot-password']);
  }

}
