import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import {
  MatButtonModule, MatNativeDateModule, MatIconModule, MatSidenavModule,
  MatListModule, MatToolbarModule, MatCardModule, MatInputModule, MatSelectModule, MatRadioModule, MatDatepickerModule, MatMenuModule, MatFormFieldModule, MatTableModule, MatTooltipModule, MatDividerModule, MatProgressSpinnerModule, MatChipsModule, MatAutocompleteModule, MatBottomSheetModule, MatExpansionModule, MatSnackBarModule, MatCheckboxModule
} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CdkTableModule } from '@angular/cdk/table';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  imports: [CommonModule,
    MatButtonModule,
    MatToolbarModule,
    MatNativeDateModule,
    MatIconModule,
    MatSidenavModule,
    MatDatepickerModule,
    MatListModule,
    MatCardModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatMenuModule,
    MatFormFieldModule,
    MatTableModule,
    MatTooltipModule,
    FlexLayoutModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatAutocompleteModule,
    CdkTableModule,
    MatBottomSheetModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatCheckboxModule,
    DragDropModule

  ],
  exports: [
    CommonModule,
    MatButtonModule,
    MatToolbarModule,
    MatNativeDateModule,
    MatIconModule,
    MatSidenavModule,
    MatDatepickerModule,
    MatListModule,
    MatCardModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatMenuModule,
    MatFormFieldModule,
    MatTableModule,
    MatTooltipModule,
    FlexLayoutModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatAutocompleteModule,
    CdkTableModule,
    MatBottomSheetModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatCheckboxModule,
    DragDropModule
  ],
})
export class CustomMaterialModule { }
