import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import sweet_alert_2 from 'sweetalert2';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';

@Injectable({
    providedIn: 'root',
})
export class SharedService {

    private apiUrl = '';
    private dashboardUrl = '';

    // isUserLoggedIn : global variable to be used across app to check if user is logged in.
    public isUserLoggedIn = false;
    public propertyUnitObject;
    public filterCriteria: any;

    constructor(private snackBar: MatSnackBar) {
        this.apiUrl = environment.appConstants.apiUrl;
        this.dashboardUrl = environment.appConstants.dashboardUrl;
        if (localStorage.getItem('access_token') !== null) {
            this.isUserLoggedIn = true;
        }
    }

    /*****************************************************************
        getAPIUrl()
        purpose : this method will return the value of apiUrl
    *******************************************************************/
    public getAPIUrl() {
        return this.apiUrl;
    }



    public getDashboardUrl() {
        return this.dashboardUrl;
    }

    /*****************************************************************
        onlyNumbers() {
        parameter: event
        purpose : this method will called when keypress event is fire and used for inly numbers validation
    *******************************************************************/
    public onlyNumbers(event) {
        let k = event.keyCode;
        return ((k >= 48 && k <= 57) || k === 46);
    }

    public deleteAlert(string) {
        return sweet_alert_2({
            position: 'center',
            allowOutsideClick: false,
            allowEscapeKey: false,
            type: 'warning',
            title: 'Do you really wish to delete ' + string + '?',
            showCancelButton: true,
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Yes'
        });
    }

    public publishAlert(string) {
        return sweet_alert_2({
            position: 'center',
            allowOutsideClick: false,
            allowEscapeKey: false,
            type: 'warning',
            title: 'Do you really wish to publish ' + string + '?',
            showCancelButton: true,
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Yes'
        });
    }


    public unpublishAlert(string) {
        return sweet_alert_2({
            position: 'center',
            allowOutsideClick: false,
            allowEscapeKey: false,
            type: 'warning',
            title: 'Do you really wish to unpublish ' + string + '?',
            showCancelButton: true,
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Yes'
        });
    }

    public successMessage(message: string, action: string) {
        const config = new MatSnackBarConfig();
        config.panelClass = ['snackbar-bg'];
        config.duration = 3000;
        this.snackBar.open(message, action, config);
    }
    public errorMessage(message: string, action: string) {
        const config = new MatSnackBarConfig();
        config.panelClass = ['snackbar-error'];
        config.duration = 3000;
        this.snackBar.open(message, action, config);
    }    /*****************************************************************
    openSnackBar()
    purpose : this method will openSnackBar
    *******************************************************************/
    openSnackBar(message: string, action: string) {
        const config = new MatSnackBarConfig();
        config.panelClass = ['snackbar-bg'];
        config.duration = 2000;
        this.snackBar.open(message, action, config);
    }
    /*****************************************************************
  openSnackBarError()
  purpose : this method will openSnackBarError
  *******************************************************************/
    openSnackBarError(message: string, action: string) {
        const config = new MatSnackBarConfig();
        config.panelClass = ['snackbar-error'];
        config.duration = 2000;
        this.snackBar.open(message, action, config);
    }
    /*****************************************************************
 openSnackBarMail()
 purpose : this method will openSnackBarError
 *******************************************************************/
    openSnackBarMail(message: string, action: string) {
        const config = new MatSnackBarConfig();
        config.panelClass = ['snackbar-mail'];
        config.duration = 2000;
        this.snackBar.open(message, action, config);
    }
}    
