import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    RouterStateSnapshot,
    Route,
    Router,
    CanLoad
} from '@angular/router';
import { Observable } from 'rxjs';


@Injectable()
export class AuthGuard implements CanActivate {

    public platformId = '';
    constructor( private route: Router,@Inject(PLATFORM_ID) platformId: string,) { 
        this.platformId = platformId;
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        let url: string = state.url;
        return this.checkLogin(url);
    }
    
    checkLogin(url: string) {
        if (isPlatformBrowser(this.platformId)) {
        if (localStorage.getItem('access_token')) {
            return true;
        }
        else {
             let pathEncoded = encodeURI(window.location.href);
                this.route.navigate(['login'], {
                    queryParams: { redirectURl: pathEncoded }
                });
                localStorage.removeItem("access_token");
                return false;
        }
    }
    }

}
