import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ngx-chart',
  templateUrl: './ngx-chart.component.html',
  styleUrls: ['./ngx-chart.component.css']
})
export class NgxChartComponent implements OnInit {

  @Input() chartType?= '';
  @Input() chartData?: any;

  @Input() showXAxis? = true;
  @Input() showYAxis? = true;
  @Input() gradient? = false;
  @Input() showLegend?= true;
  @Input() legendPosition? = 'below';
  @Input() showXAxisLabel? = true;
  @Input() xAxisLabel? = '';
  @Input() showYAxisLabel? = true;
  @Input() yAxisLabel?= '';
  
  @Input() chartWidth?: any;
  @Input() chartHeight?: any;

  @Input() view: any[] = [];

  @Input() colorScheme? = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#FFCC00']
  };

  @Input() colorBlue? = {
    domain: ['#459efd']
  };

  @Input() explodeSlices?= false;
  @Input() doughnut = false;
  @Input() showLabels = true;
  
  constructor() { 

  }

  ngOnInit() {
    if (this.chartWidth && this.chartHeight) {
      this.view = [Number(this.chartWidth), Number(this.chartHeight)];
    } else {
      this.view = undefined;
    }
  }

}
