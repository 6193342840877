import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UsersAPI } from '@app/shared/apis/users.api';
import { SharedService } from '@app/core/services/shared.service';
import { LayoutService } from '../layout.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  public version = '2.3';
  public organizationType: any;
  public showAdminContent = false;
  public showManagerContent = false;
  public showClientAdminContent = false
  userData: any; 
  userInfo: any;
  @Output() closeSidenav = new EventEmitter();
  constructor(private usersAPI: UsersAPI, public sharedService: SharedService, public layoutService: LayoutService, private router: Router) { }

  ngOnInit() {
    this.getUserInfo();
  }

  getUserInfo() {
    this.usersAPI.getUserInfo().subscribe(
      response => {
        this.userData = response
        this.organizationType = this.userData.orgType
        this.layoutService.currentUser = this.userData;
        if (this.organizationType === "SuperAdmin") {
          this.showAdminContent = true;
          } else if(this.organizationType === "PropertyManager") {
           this.showManagerContent = true;
        }
        else if (this.organizationType === "ClientAdmin") {
          this.showClientAdminContent = true;
        }
      }
    )
  }
}
