import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SharedService } from '@app/core/services/shared.service';
import { StorageService } from '@app/core/services/storage.service';
import { User } from '@app/core/models/user.model';

@Injectable()
export class UsersAPI {
    listPageIndex=0;
    listPageSize=10;
    pageIndex = 0
    pageSize;
    groupPageSize =10;
    groupPageIndex;
    apiParameter;
    searchString;
    groupSearchString;
    private apiUrl = '';
    closedApiParameter;
    constructor(public sharedServices: SharedService, private localStorageService: StorageService, private http: HttpClient) {
        this.apiUrl = this.sharedServices.getAPIUrl();
    }

    /*************************************************************************
    Purpose: Get Admin Dashboard Data
    ***************************************************************************/
    public getAdminDashboardData () {
         let data = {};
         const options = {
              headers: new HttpHeaders({
              'content-type': 'application/json'
          })
        }
    return this.http.post(this.apiUrl + '/api/Properties/GetDashboardDataForAdminPanel', data, options);
  }

    /*************************************************************************
    Purpose: Get user
    Parameters: user uid
    ***************************************************************************/
    getUser(id: string): Observable<User> {
        return this.http.get<User>(this.apiUrl + '/api/Users/Get/' + id);
    }

    getUserDetails(id: string): Observable<User> {
        return this.http.get<User>(this.apiUrl + '/api/Users/Get/' + id);
    }

    /*************************************************************************
    Purpose: Get all user to display list on user dashboard
    ***************************************************************************/
    getAllUsers(userFilterCriteria) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        let body = JSON.stringify(userFilterCriteria);
        return this.http.post(this.apiUrl + '/api/Users/GetAll', body, options);
    }

    /*************************************************************************
    Purpose: add new user
    Parameters: user to add
    ***************************************************************************/
    isEmaiExist(emailid: string) {
        return this.http.get(this.apiUrl + '/api/Users/IsEmailExists?emailId=' + emailid);
    }


    /*************************************************************
     Parameter: 
     Description: This method will get dashboard
    **************************************************************/
    getDashboradData(object) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        let body = JSON.stringify(object)
        return this.http.post(this.apiUrl + '/api/Properties/GetDashboardData', body, options)
    }

    /*****************************************************************
        getAllPropertyManager()
        param: 
        purpose : this method will call api to get all Property-Managers
    *******************************************************************/
    public getAllPropertyManagerSuggestions(object) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post(this.apiUrl + '/api/PropertyManagers/GetAll', object, options);
    }

    /*****************************************************************
        getAllPropertySuggestions()
        param: 
        purpose : this method will call api to get all Properties
    *******************************************************************/
    public getAllPropertySuggestions(object) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const body = JSON.stringify(object);
        return this.http.post(this.apiUrl + '/api/Properties/GetAll', body, options);
    }

    /*************************************************************************
    Purpose: add new user
    Parameters: user to add
    ***************************************************************************/
    inviteUser(user: User) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        let body = JSON.stringify(user);
        return this.http.post(this.apiUrl + '/api/Users/InviteUser', body, options);
    }

    /*************************************************************************
    Purpose: to update the existing user
    Parameter: user to update and uid
    ***************************************************************************/
    updateUser(user: User) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        }
        let body = JSON.stringify(user);
        return this.http.put(this.apiUrl + '/api/Users/Update/' + user.uId, body, options);
    }

    /*************************************************************************
    Purpose: to delete the existing user
    Parameter: user uId and user version
    ***************************************************************************/
    deleteUser(id: string, version: any): Observable<User> {
        return this.http.delete<User>(this.apiUrl + '/api/Users/Delete/' + id + '?version=' + version);
    }

    /*************************************************************************
    Purpose: to get properties to add user claims
    ***************************************************************************/
    getAllPropertiesToAddUser(uid: string) {
        const options = {
            headers: new HttpHeaders({
                'Content-type': 'application/json'
            })
        }
        return this.http.get(this.apiUrl + '/api/Properties/GetPropertiesForAddUser?orgUId=' + uid, options);
    }


    getPropertyDetails(id: string) {
        return this.http.get(this.apiUrl + '/api/Properties/Get/' + id);
    }

    /************************************************************
     Description: This method will get claims for property by property uid
    **************************************************************/
    getRequiredClaimsForProprty(propertyuid) {
        const options = {
            headers: new HttpHeaders({
                'Content-type': 'application/json'
            })
        }
        return this.http.get(this.apiUrl + '/api/Properties/GetRequiredClaimsForProperty?propertyUId=' + propertyuid, options);
    }

    /************************************************************
     Description: This method will update the user profile
    **************************************************************/

    uploadUserProfile(file): Observable<string> {
        let formData: FormData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post<string>(this.apiUrl + '/api/Users/Upload', formData);
    }

    /**********************************************************************
     PArameter: file name
     Description: This method will remove the user profile from database
    ***********************************************************************/

    deleteUploadedProfile(fileName) {
        const options = {
            headers: new HttpHeaders({
                'Content-type': 'application/json'
            })
        }
        return this.http.post(this.apiUrl + '/api/Users/DeleteUploadedFile?fileName=' + fileName, options)
    }


    /************************************************************
    Description: This method will get user info
   **************************************************************/
    getUserInfo() {
        const options = {
            headers: new HttpHeaders({
                'Content-type': 'application/json'
            })
        }
        return this.http.get(this.apiUrl + '/api/Users/GetUserInfo', options);
    }

    /************************************************************
     Description: This method will get user info
    **************************************************************/
    getUserInformation() {
        const options = {
            headers: new HttpHeaders({
                'Content-type': 'application/json'
            })
        }
        return this.http.get(this.apiUrl + '/api/Users/GetUserInfoNew', options);
    }



    /*************************************************************************
     Purpose: to download epc document in excel format
    ***************************************************************************/
    exportAsExcel(excelData): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        let body = JSON.stringify(excelData);
        return this.http.post(this.apiUrl + '/api/Users/ExportAsExcel', body, { observe: 'response', responseType: 'blob', headers: headers });
    }


    /*************************************************************************
     Purpose: get updated version information 
    ***************************************************************************/
    getCurrentAppVersion() {
        return this.http.get(this.apiUrl + '/api/Users/GetCurrentVersionNumber');
    }

    getInvitationDetails(uid) {
        return this.http.get(this.apiUrl + '/api/Users/GetInvitationDetailsByUserUId?uId=' + uid);
    }

    getAllOrganizations(orgtype) {
        const options = {
            headers: new HttpHeaders({
                'Content-type': 'application/json'
            })
        }
        return this.http.get(this.apiUrl + '/api/Properties/GetOrganizationsForAddUser?orgType=' + orgtype, options);
    }


}